var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',{style:((_vm.primary ? 'color: white !important;' : '') +
    (_vm.primary
      ? ("background: " + (_vm.$vuetify.theme.currentTheme.primary) + " !important;")
      : '') +
    (_vm.main
      ? ("background: " + (_vm.$vuetify.theme.currentTheme['light-grey']) + " !important;")
      : '')),attrs:{"to":_vm.to,"exact":_vm.exact},on:{"click":_vm.handleClick}},[_c('v-tooltip',{attrs:{"disabled":!_vm.navMiniVariant,"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-list-item-icon',_vm._g(_vm._b({},'v-list-item-icon',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.primary ? 'white' : ''}},[_vm._v(_vm._s(_vm.icon))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.label))])]),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.label)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }