export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Laborer = {
  __typename?: "Laborer";
  firstname: Scalars["String"];
  id: Scalars["Int"];
  isCadCam: Scalars["Boolean"];
  isControl: Scalars["Boolean"];
  isDriver: Scalars["Boolean"];
  state: LaborerStateEnum;
  status: LaborerStatusEnum;
  surname: Scalars["String"];
  ticketLimit: Scalars["Int"];
  username: Scalars["String"];
};

export type LaborerLogin = {
  __typename?: "LaborerLogin";
  accessToken: Scalars["String"];
  expiresIn: Scalars["Int"];
  laborer: Laborer;
  laborerId: Scalars["Int"];
  refreshToken: Scalars["String"];
};

export enum LaborerStateEnum {
  Offline = "offline",
  Online = "online",
}

export enum LaborerStatusEnum {
  Active = "active",
  Deleted = "deleted",
  Inactive = "inactive",
}

export type LoginInput = {
  password: Scalars["String"];
  username: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  login: LaborerLogin;
  refreshToken: LaborerLogin;
  resetPassword: Scalars["Boolean"];
  setPassword: Scalars["Boolean"];
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput;
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationSetPasswordArgs = {
  input: SetPasswordInput;
};

export type Query = {
  __typename?: "Query";
  verifyToken: Laborer;
};

export type QueryVerifyTokenArgs = {
  input: VerifyTokenInput;
};

export type RefreshTokenInput = {
  refreshToken: Scalars["String"];
};

export type ResetPasswordInput = {
  username: Scalars["String"];
};

export type SetPasswordInput = {
  password: Scalars["String"];
  token: Scalars["String"];
};

export type VerifyTokenInput = {
  token: Scalars["String"];
};

export type SetPasswordMutationVariables = Exact<{
  input: SetPasswordInput;
}>;

export type SetPasswordMutation = {
  __typename?: "Mutation";
  setPassword: boolean;
};
