



















import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { Rules } from "../../../utils/veeValidate";
import { EnumValues } from "../tables/types";

@Component({
  components: {
    ValidationProvider,
  },
})
export default class SelectField extends Vue {
  private innerValue: any;

  @Prop({ required: false, type: Array, default: "" })
  private values!: EnumValues[];

  @Prop({ required: false, type: String })
  private label?: string;

  @Prop({ required: true, type: String })
  private name!: string;

  @Prop({ required: false, type: String, default: "" })
  private hint!: string;

  @Prop({ required: false, type: Object })
  private rules?: Rules;

  constructor() {
    super();
    this.innerValue = null;
  }

  reduceForFilter(selected: EnumValues): any {
    return selected.value;
  }
}
