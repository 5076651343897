var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-color-white rounded pa-4"},[_c('TableHeader',{attrs:{"table-options":_vm.tableOptions,"title":"Všechny zakázky","use-search-small":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex gap-2 mt-2"},[_c('Button',{attrs:{"outlined":!_vm.importantFilter,"color":"secondary"},on:{"click":_vm.updateFilterImportant}},[_vm._v("Důležité")]),_c('Button',{attrs:{"outlined":!_vm.urgentFilter,"color":"error"},on:{"click":_vm.updateFilterUrgent}},[_vm._v("Hoří")]),_c('Button',{attrs:{"outlined":!_vm.problemFilter,"color":"error"},on:{"click":_vm.updateFilterProblem}},[_vm._v("Problém")])],1)]},proxy:true},{key:"advancedActions",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('TextField',{attrs:{"clearable":"","name":"number","label":"Č. zakázky"},on:{"change":_vm.prepareAdvancedFilter},model:{value:(_vm.numberFilter),callback:function ($$v) {_vm.numberFilter=$$v},expression:"numberFilter"}})],1),_c('v-col',[_c('SelectBox',{attrs:{"clearable":"","label":"Status","item-text":"text","item-value":"value","items":_vm.statuses,"name":"status"},on:{"change":_vm.prepareAdvancedFilter},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1)],1)]},proxy:true}])}),_c('Table',{attrs:{"table-options":_vm.tableOptions},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('ActionsWrapper',[_c('RoundButton',{attrs:{"icon":"pin-outline","disabled":item.isPinned},on:{"click":function () { return _vm.pin(item); }}}),_c('RoundButton',{attrs:{"to":{
            name: _vm.Routes.TicketDetail,
            params: {
              id: item.id,
            },
          },"icon":"pencil"}})],1)]}},{key:"number",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
          name: _vm.Routes.TicketDetail,
          params: {
            id: item.id,
          },
        }}},[_vm._v(" "+_vm._s(item.number)+" ")])]}},{key:"doctor",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(value.companyName)+" ")]}},{key:"laborer",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(value.firstname)+" "+_vm._s(value.surname)+" ")]}},{key:"patient",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(value.firstname)+" "+_vm._s(value.surname)+" ")]}},{key:"created",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("toLocalDateTime")(value))+" ")]}},{key:"process",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("toLocalDateTime")(value.deadline))+" ")]}},{key:"price",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("toCZK")(value))+" ")]}},{key:"operations",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.currentOperationLaborer(value))+" ")]}},{key:"important",fn:function(ref){
        var value = ref.value;
return [(value)?_c('v-icon',{attrs:{"size":"20","color":"inherit","title":"Příznak důležité"}},[_vm._v(" mdi-bookmark ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }