import { render, staticRenderFns } from "./StatusCard.vue?vue&type=template&id=100038bc&scoped=true&"
import script from "./StatusCard.vue?vue&type=script&lang=ts&"
export * from "./StatusCard.vue?vue&type=script&lang=ts&"
import style0 from "./StatusCard.vue?vue&type=style&index=0&id=100038bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "100038bc",
  null
  
)

export default component.exports