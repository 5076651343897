var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.shouldBeVisible)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"data-table",attrs:{"loading":_vm.loading,"headers":_vm.tableOptions.headers,"no-data-text":_vm.$t('table.noData'),"items":_vm.tableState.items,"items-per-page":_vm.tableOptions.defaultPerPage,"options":_vm.tableStateOptions,"page":_vm.tableState.pager.page,"hide-default-footer":_vm.hideFooter,"footer-props":{
            'items-per-page-options': _vm.itemsPerPage,
            'items-per-page-text': _vm.$t(
              _vm.tableOptions.itemPerPageText
                ? _vm.tableOptions.itemPerPageText
                : 'table.itemsPerPage'
            ),
            'page-text': _vm.$t(
              _vm.tableOptions.pageText
                ? _vm.tableOptions.pageText
                : 'table.numberOfPages',
              [_vm.tableState.pager.page, _vm.tableState.pager.last]
            ),
          },"server-items-length":_vm.tableState.pager.total,"single-select":""},on:{"update:options":function($event){_vm.tableStateOptions=$event},"update:page":function($event){return _vm.$set(_vm.tableState.pager, "page", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{class:{ selected: props.isSelected },on:{"click":function($event){return _vm.onRowClick(props)}}},[_vm._l((_vm.tableOptions.headers),function(header,index){return [(_vm.$scopedSlots[header.value.toString()])?_c('td',{key:index},[_vm._t(header.value,null,{"value":props.item[header.value]},props)],2):_c('td',{key:index,class:header.align ? ("text-" + (header.align)) : ''},[_vm._v(" "+_vm._s(props.item[header.value])+" ")])]})],2)]}}],null,true)})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }