








import { Component, Vue } from "vue-property-decorator";
import AppLayout from "../components/commons/layouts/AppLayout.vue";
import AllTickets from "../components/app/dashboard/AllTickets.vue";
import PinnedTickets from "../components/app/dashboard/PinnedTickets.vue";

@Component({
  components: {
    AllTickets,
    AppLayout,
    PinnedTickets,
  },
})
export default class DashboardPage extends Vue {}
