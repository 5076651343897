


































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Location } from "vue-router";
import { userSettings } from "../../../../utils/userSettings";

@Component
export default class NavItemSubmenu extends Vue {
  @Prop({ required: true, type: Boolean })
  private navMiniVariant!: boolean;

  @Prop({ required: true, type: String })
  private label!: string;

  @Prop({ required: true, type: String })
  private icon!: string;

  @Prop({ required: true, type: Array })
  private items!: { show?: boolean; label: string; to: Location }[];

  private open: boolean;

  constructor() {
    super();
    if (!this.navMiniVariant) {
      this.open = userSettings.getNavSubmenuExpanded(this.label);
    } else {
      this.open = false;
    }
  }

  private updateUserSettings(): void {
    const prevVal = this.open;
    if (!this.navMiniVariant) {
      userSettings.updateNavSubmenuExpanded(this.label, !prevVal);
    }
  }
}
