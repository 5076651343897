













import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import {
  NotificationsGetters,
  notificationsNamespace,
  WebsocketNotification,
} from "../../../store/modules/notifications";
import ToastNotification from "./ToastNotification.vue";

@Component({
  components: { ToastNotification },
})
export default class ToastNotificationsSnackbar extends Vue {
  @Getter(
    `${notificationsNamespace}/${NotificationsGetters.GetWebsocketNotifications}`
  )
  notifications!: WebsocketNotification[];
  displayedNotifications: Record<number, boolean> = {};

  calcMargin(i: number) {
    return i * 160 + "px !important";
  }

  onCloseNotification(key: number) {
    this.displayedNotifications = {
      ...this.displayedNotifications,
      [key]: false,
    };
  }

  @Watch("notifications")
  newNotification(notifications: WebsocketNotification[]) {
    this.displayedNotifications[notifications[0].created] = true;
  }
}
