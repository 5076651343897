





























import { Component, Prop, Vue } from "vue-property-decorator";
import { Location } from "vue-router";

@Component
export default class NavItem extends Vue {
  @Prop({ required: true, type: Boolean })
  private navMiniVariant!: boolean;

  @Prop({ required: true, type: String })
  private label!: string;

  @Prop({ required: true, type: String })
  private icon!: string;

  @Prop({ required: false, type: Object })
  private to!: Location;

  @Prop({ required: false, type: Boolean, default: false })
  private primary!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  private exact!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  private main!: boolean;

  private handleClick(): void {
    this.$emit("click");
  }
}
