



































import Modal from "../../Modal.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { eventBus } from "../../../../utils/eventBus";
import { userSettings } from "../../../../utils/userSettings";
import { EventBus } from "../../../../enums";
import { QuickFilter, TableOptions } from "../../../../types";
import TextField from "../../inputsAndControls/TextField.vue";
import RoundButton from "../../inputsAndControls/RoundButton.vue";
import { Rules } from "../../../../utils/veeValidate";
import Button from "../../inputsAndControls/Button.vue";
import deepClone from "lodash.clonedeep";
import { ValidationObserver } from "vee-validate";

type UpdateSavedFiltersInputs = "id" | "name" | "filter";
type UpdateSavedFiltersForm = { [index in UpdateSavedFiltersInputs]: any }[];
type UpdateSavedFiltersRules = { [index in UpdateSavedFiltersInputs]?: Rules };

@Component({
  components: {
    Modal,
    TextField,
    RoundButton,
    Button,
    ValidationObserver,
  },
})
export default class UpdateSavedFiltersModal extends Vue {
  @Prop({ required: true, type: Object })
  private tableOptions!: TableOptions;

  private form: UpdateSavedFiltersForm = [];

  private rules: UpdateSavedFiltersRules = {
    name: {
      required: true,
    },
  };

  private isOpen = false;

  public created() {
    eventBus.$on(
      `${EventBus.UpdateSavedFiltersModal}/${this.tableOptions.namespace}`,
      (payload: QuickFilter[]) => {
        this.form = deepClone(payload);
        this.isOpen = true;
      }
    );
  }

  private handleRemove(index: number): void {
    this.form.splice(index, 1);
  }

  private async handleSave(): Promise<void> {
    if (!this.form) return;
    const valid = await (this.$refs.form as any).validate();
    if (valid) {
      userSettings.updateTableQuickFilters(
        this.tableOptions.namespace,
        this.form
      );
    }
    this.isOpen = false;
  }
}
