










import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class StatusCard extends Vue {
  @Prop({ type: Number, default: null })
  readonly score!: number;

  @Prop({ type: String, default: "" })
  readonly title!: string;

  @Prop({ type: Boolean, default: false })
  readonly hasError!: boolean;

  @Prop({ type: Number, default: null })
  readonly badge!: number;
}
