



































import { Component, Prop, Vue } from "vue-property-decorator";
import { Notification } from "../../../types/gqlGeneratedPrivate";
import { DateTime } from "luxon";
import { EventBus, Routes } from "../../../enums";
import store from "../../../store";
import {
  NotificationsActions,
  notificationsNamespace,
} from "../../../store/modules/notifications";
import { eventBus } from "../../../utils/eventBus";

@Component({ components: {} })
export default class NotificationSummaryItem extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isUnread!: boolean;

  @Prop({ type: Object, required: true })
  readonly notification!: Notification;

  Routes = Routes;

  isOpen = false;

  get timeAgo() {
    return DateTime.fromISO(this.notification.created).toRelative({
      locale: "cs",
    });
  }

  get fullName() {
    if (!this.notification.laborer) return "";

    return `${this.notification.laborer.surname} ${this.notification.laborer.firstname}`;
  }

  get externalData() {
    if (!this.notification.data) return null;

    return JSON.parse(this.notification.data)?.id;
  }

  async onClick() {
    if (this.isUnread) {
      await store.dispatch(
        `${notificationsNamespace}/${NotificationsActions.Read}`,
        { id: this.notification.id }
      );
    }
    eventBus.$emit(EventBus.NotificationToggleModal, false);

    if (!this.externalData) {
      return;
    }

    await this.$router
      .push({
        name: Routes.TicketDetail,
        params: { id: this.externalData },
      })
      .catch((err) => err);
  }

  get showBtnWholeContent() {
    return this.notification.content.length > 36;
  }
}
