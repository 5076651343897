



















import { Component, Vue } from "vue-property-decorator";
import { authService } from "../../../../utils/authService";
import { router } from "../../../../utils/router";
import { Routes } from "../../../../enums";

@Component({
  components: {},
})
export default class ToolbarItems extends Vue {
  private logout() {
    authService.invalidateAuthentication(true);
  }

  private profile() {
    if (router.currentRoute.name !== Routes.Profile) {
      router.push({ name: Routes.Profile });
    }
  }
}
