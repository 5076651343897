




















import { Component, Prop, Vue } from "vue-property-decorator";
import NotificationSummaryItem from "./NotificationSummaryItem.vue";
import { Notification } from "../../../types/gqlGeneratedPrivate";

@Component({
  components: { NotificationSummaryItem },
})
export default class NotificationSummaryContent extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isUnread!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly allLoaded!: boolean;

  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: Array, required: true })
  readonly notifications!: Notification[];
}
