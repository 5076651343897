















import { Component, Prop, Vue } from "vue-property-decorator";
import SvgIcon from "../../../app/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class SideBarItem extends Vue {
  @Prop({ type: String, required: true })
  private text!: string;

  @Prop({ type: Object, default: () => ({}) })
  private to!: object;

  @Prop({ type: String, default: "" })
  private iconName!: string;

  @Prop({ type: Boolean, default: false })
  private fill!: boolean;

  @Prop({ type: Boolean, default: false })
  private stroke!: boolean;
}
