














import { Component, Prop, Vue } from "vue-property-decorator";
import StatusCard from "../commons/StatusCard.vue";
import { Action, Getter } from "vuex-class";
import {
  StatusCards,
  StatusCardsActions,
  StatusCardsGetters,
  statusCardsNamespace,
} from "../../store/modules/status-cards/types";
import { StatusCardsState } from "../../store/modules/status-cards";

@Component({
  components: {
    StatusCard,
  },
})
export default class StatusCardList extends Vue {
  @Prop({ type: Number, default: undefined })
  protected refreshInterval?: number;

  @Getter(`${statusCardsNamespace}/${StatusCardsGetters.GetState}`)
  numbers!: StatusCards;

  @Action(`${statusCardsNamespace}/${StatusCardsActions.Fetch}`)
  fetchStatusCards!: () => Promise<StatusCardsState>;

  protected refresher?: number;

  constructor() {
    super();
    this.setRefreshInterval();
  }

  created() {
    this.fetchStatusCards();
  }

  protected setRefreshInterval(): void {
    if (!this.refreshInterval) return;

    this.refresher = setInterval(() => {
      this.fetchStatusCards();
    }, this.refreshInterval * 1000);
  }

  public beforeDestroy(): void {
    if (this.refresher) clearInterval(this.refresher);
  }
}
