

















































import { Component, Prop, Vue } from "vue-property-decorator";
import QuickFilters from "./partials/QuickFilters.vue";
import AdvancedFilters from "./partials/AdvancedFilters.vue";
import Button from "../inputsAndControls/Button.vue";
import { TableFilter, TableFilterPayload, TableOptions } from "./types";
import TextField from "../inputsAndControls/TextField.vue";
import store from "../../../store";
import { TablesActions } from "../../../store/modules/tables";
import SearchFilter from "./partials/SearchFilter.vue";

@Component({
  components: {
    QuickFilters,
    AdvancedFilters,
    Button,
    TextField,
    SearchFilter,
  },
})
export default class TableHeader extends Vue {
  @Prop({ required: true, type: Object })
  private tableOptions!: TableOptions;

  @Prop({ required: false, type: Boolean, default: true })
  private pt!: boolean;

  @Prop({ type: Boolean, default: false })
  private useSearch!: boolean;

  @Prop({ type: Boolean, default: false })
  private useSearchSmall!: boolean;

  @Prop({ type: String, default: "" })
  private title!: string;

  @Prop({ type: Boolean, default: false })
  private useQuickFilter!: boolean;

  @Prop({ type: Boolean, default: false })
  private useAdvancedFilter!: boolean;

  private callFilter(filter: TableFilter): void {
    store.dispatch(`${this.tableOptions.namespace}/${TablesActions.Filter}`, {
      namespace: this.tableOptions.namespace,
      filter,
    } as TableFilterPayload);
  }

  get hasTableActions() {
    return (
      this.useSearch ||
      this.useSearchSmall ||
      this.useQuickFilter ||
      !!this.$slots["actions"]
    );
  }
}
