




import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "../../../enums";
import { eventBus } from "../../../utils/eventBus";
import NotificationSummary from "./NotificationSummary.vue";
import ToastNotification from "./ToastNotification.vue";
import { Action } from "vuex-class";
import {
  NotificationsActions,
  notificationsNamespace,
} from "../../../store/modules/notifications";

@Component({
  components: {
    ToastNotification,
    NotificationSummary,
  },
})
export default class NotificationModal extends Vue {
  @Action(`${notificationsNamespace}/${NotificationsActions.Fetch}`)
  private loadNotifications!: (payload: { unread: boolean }) => Promise<void>;

  isOpen = false;

  created(): void {
    eventBus.$on(EventBus.NotificationToggleModal, async (value?: boolean) => {
      if (!this.isOpen && value !== false) {
        await this.loadNotifications({ unread: true });
        await this.loadNotifications({ unread: false });
      }

      if (value !== undefined) {
        this.isOpen = value;
      } else {
        this.isOpen = !this.isOpen;
      }
    });
  }
}
