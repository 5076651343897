
































import SvgIcon from "../../../app/SvgIcon.vue";
import NotificationModal from "../../../app/notification/NotificationModal.vue";
import ToastNotification from "../../../app/notification/ToastNotification.vue";
import { Component, Vue } from "vue-property-decorator";
import { Routes } from "../../../../enums";
import { Getter } from "vuex-class";
import { EventBus } from "../../../../enums";
import { eventBus } from "../../../../utils/eventBus";
import { AuthGetters, authNamespace } from "../../../../store/modules/auth";
import { authService } from "../../../../utils";
import {
  NotificationsGetters,
  notificationsNamespace,
} from "../../../../store/modules/notifications";
import ToastNotificationsSnackbar from "../../../app/notification/ToastNotificationsSnackbar.vue";

@Component({
  components: {
    ToastNotificationsSnackbar,
    SvgIcon,
    NotificationModal,
    ToastNotification,
  },
})
export default class TopBar extends Vue {
  @Getter(`${authNamespace}/${AuthGetters.GetFullName}`)
  fullName!: string;

  @Getter(
    `${notificationsNamespace}/${NotificationsGetters.GetUnreadNotificationsCount}`
  )
  notificationsCount!: number;
  routes = Routes;

  handleLogout() {
    authService.invalidateAuthentication(true);
  }

  async handleNotificationsClick(): Promise<void> {
    eventBus.$emit(EventBus.NotificationToggleModal);
  }
}
