





























































import ActionsWrapper from "../../commons/tables/ActionsWrapper.vue";
import Button from "../../commons/inputsAndControls/Button.vue";
import RoundButton from "../../commons/inputsAndControls/RoundButton.vue";
import SvgIcon from "../SvgIcon.vue";
import Table from "../../commons/tables/Table.vue";
import TableHeader from "../../commons/tables/TableHeader.vue";
import { Action, Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import {
  Operation,
  OperationStatusEnum,
  Ticket,
  TicketFilterEnum,
  UnpinTicketMutation,
  UnpinTicketMutationVariables,
} from "../../../types/gqlGeneratedPrivate";
import { Routes } from "../../../enums";
import { TableOptions, TableRefreshPayload } from "../../../types";
import {
  TableGetters,
  TablesActions,
  TablesNamespaces,
} from "../../../store/modules/tables";
import { api } from "../../../api";
import { apiClient } from "../../../utils";
import { toCZK } from "../../../filters/money";
import { toLocalDateTime } from "../../../filters/datetime";
import {
  StatusCardsActions,
  statusCardsNamespace,
  StatusCardsState,
} from "../../../store/modules/status-cards";

@Component({
  filters: {
    toLocalDateTime,
    toCZK,
  },
  components: {
    ActionsWrapper,
    Button,
    RoundButton,
    SvgIcon,
    Table,
    TableHeader,
  },
})
export default class TicketsPage extends Vue {
  Routes = Routes;

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.TicketsTable,
  })
  refreshTicketsTable!: (payload: TableRefreshPayload) => Promise<void>;

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.PinnedTicketsTable,
  })
  refreshPinnedTicketsTable!: (payload: TableRefreshPayload) => Promise<void>;

  @Action(`${statusCardsNamespace}/${StatusCardsActions.Fetch}`)
  fetchStatusCards!: () => Promise<StatusCardsState>;

  @Getter(`${TablesNamespaces.PinnedTicketsTable}/${TableGetters.GetTotal}`)
  total!: number;

  get noMoreThan10() {
    if (this.total > 10) {
      return false;
    }
    return true;
  }

  tableOptions: TableOptions<Ticket, TicketFilterEnum> = {
    defaultSortBy: ["number"],
    refreshInterval: 60,
    headers: [
      {
        text: "Č. zakázky",
        sortable: true,
        align: "start",
        value: "number",
      },
      {
        text: "Odběratel",
        sortable: true,
        align: "start",
        value: "doctor",
      },
      {
        text: "Laborant",
        sortable: true,
        align: "start",
        value: "laborer",
      },
      {
        text: "Pacient",
        sortable: true,
        align: "start",
        value: "patient",
      },
      {
        text: "Zadáno",
        sortable: true,
        align: "start",
        value: "created",
      },
      {
        text: "Předání",
        sortable: true,
        align: "start",
        value: "process",
      },
      {
        text: "Cena",
        sortable: true,
        align: "start",
        value: "price",
      },
      {
        text: "Stanoviště",
        sortable: false,
        align: "start",
        value: "operations",
      },
      {
        text: "",
        sortable: false,
        align: "start",
        value: "important",
      },
      {
        text: "",
        sortable: false,
        value: "actions",
      },
    ],
    namespace: TablesNamespaces.PinnedTicketsTable,
  };

  async unpin(ticket: Ticket): Promise<void> {
    const { data } = await apiClient.callGraphqlPrivate<
      UnpinTicketMutation,
      UnpinTicketMutationVariables
    >({
      ...api.ticket.unpin,
      variables: {
        id: ticket.id,
      },
    });

    if (data?.unpinTicket) {
      this.refreshTicketsTable({
        namespace: TablesNamespaces.TicketsTable,
      });
      this.refreshPinnedTicketsTable({
        namespace: TablesNamespaces.PinnedTicketsTable,
      });
      this.fetchStatusCards();
    }
  }

  currentOperationLaborer(operations: Operation[]): string {
    const reverseOperations = operations.slice().reverse();
    let operation = reverseOperations.find(
      (o) => o.status === OperationStatusEnum.InProgress
    );
    if (!operation) {
      operation = reverseOperations.find(
        (o) => o.status === OperationStatusEnum.Done
      );
    }

    if (operation) {
      const firstname = operation.laborer.firstname;
      const surname = operation.laborer.surname;
      return `${firstname} ${surname}`;
    }
    return "";
  }
}
