
















































import { Component, Prop, Vue } from "vue-property-decorator";
import MainNav from "./partials/MainNav.vue";
import SideBar from "./partials/SideBar.vue";
import TopBar from "./partials/TopBar.vue";
import Toolbar from "./partials/Toolbar.vue";
import StatusCardList from "../../app/StatusCardList.vue";

@Component({
  components: {
    MainNav,
    Toolbar,
    SideBar,
    TopBar,
    StatusCardList,
  },
})
export default class AdminLayout extends Vue {
  @Prop({ type: Boolean, required: false })
  private disableStatus!: boolean;

  @Prop({ type: Boolean, required: false })
  private noBackground!: boolean;

  private navigationDrawer: boolean;
  private mobileVariantWidth = this.$vuetify.breakpoint.thresholds.xs;

  constructor() {
    super();
    this.navigationDrawer = window.innerWidth >= this.mobileVariantWidth;
  }

  public handleNavDrawerToggle() {
    this.navigationDrawer = !this.navigationDrawer;
  }
}
