








































import { Component, Prop, Vue } from "vue-property-decorator";
import NavItem from "./NavItem.vue";
import NavItemSubmenu from "./NavItemSubmenu.vue";
import { Routes } from "../../../../enums";

@Component({
  components: {
    NavItem,
    NavItemSubmenu,
  },
})
export default class MainNav extends Vue {
  @Prop({ required: true, type: Boolean })
  private navMiniVariant!: boolean;

  private Routes = Routes;
}
