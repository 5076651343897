





















































































































import ActionsWrapper from "@/components/commons/tables/ActionsWrapper.vue";
import Button from "@/components/commons/inputsAndControls/Button.vue";
import RoundButton from "@/components/commons/inputsAndControls/RoundButton.vue";
import SvgIcon from "../SvgIcon.vue";
import Table from "@/components/commons/tables/Table.vue";
import TableHeader from "@/components/commons/tables/TableHeader.vue";
import { Action } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { Routes } from "../../../enums";
import { TablesActions, TablesNamespaces } from "../../../store/modules/tables";
import {
  FilterOperatorEnum,
  TableColumnDataType,
  TableFilter,
  TableFilterItem,
  TableFilterPayload,
  TableOptions,
  TableRefreshPayload,
} from "../../../types";
import {
  Operation,
  OperationStatusEnum,
  PinTicketMutation,
  PinTicketMutationVariables,
  Ticket,
  TicketFilterEnum,
} from "../../../types/gqlGeneratedPrivate";
import { api } from "../../../api";
import { apiClient } from "../../../utils";
import { toCZK } from "../../../filters/money";
import { toLocalDateTime } from "../../../filters/datetime";
import { StatusCardsActions, statusCardsNamespace, StatusCardsState } from "../../../store/modules/status-cards";
import SelectBox from "@/components/commons/inputsAndControls/SelectBox.vue";
import DateField from "@/components/commons/inputsAndControls/DateField.vue";
import TextField from "@/components/commons/inputsAndControls/TextField.vue";

@Component({
  filters: {
    toLocalDateTime,
    toCZK,
  },
  components: {
    TextField,
    DateField,
    SelectBox,
    ActionsWrapper,
    Button,
    RoundButton,
    SvgIcon,
    Table,
    TableHeader,
  },
})
export default class TicketsPage extends Vue {
  Routes = Routes;

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.TicketsTable,
  })
  refreshTicketsTable!: (payload: TableRefreshPayload) => Promise<void>;

  @Action(TablesActions.Filter, {
    namespace: TablesNamespaces.TicketsTable,
  })
  filterTicketsTable!: (payload: TableFilterPayload) => Promise<void>;

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.PinnedTicketsTable,
  })
  refreshPinnedTicketsTable!: (payload: TableRefreshPayload) => Promise<void>;

  @Action(`${statusCardsNamespace}/${StatusCardsActions.Fetch}`)
  fetchStatusCards!: () => Promise<StatusCardsState>;

  importantFilter = false;
  urgentFilter = false;
  problemFilter = false;
  numberFilter = "";
  statusFilter = "";
  statuses = [
    {
      value: "Done",
      text: "Dokončeno",
    },
    {
      value: "Handover",
      text: "Předáno",
    },
    {
      value: "InProgress",
      text: "Ve výrobě",
    },
    {
      value: "Returned",
      text: "Vrácené",
    },
    {
      value: "Todo",
      text: "Nové",
    },
  ];

  get customFilter(): TableFilter<TicketFilterEnum> {
    const buildFilter = (column: TicketFilterEnum) => ({
      filter: {
        column: column,
        operator: FilterOperatorEnum.Equal,
        values: ["1"],
      },
    });
    const customFilter = [];
    if (this.importantFilter) {
      customFilter.push(buildFilter(TicketFilterEnum.Important));
    }
    if (this.urgentFilter) {
      customFilter.push(buildFilter(TicketFilterEnum.Urgent));
    }
    if (this.problemFilter) {
      customFilter.push(buildFilter(TicketFilterEnum.Problem));
    }
    return customFilter;
  }

  updateFilterImportant() {
    this.importantFilter = !this.importantFilter;
    this.filterTicketsTable({
      namespace: TablesNamespaces.TicketsTable,
      filter: this.customFilter,
    });
  }

  updateFilterUrgent() {
    this.urgentFilter = !this.urgentFilter;
    this.filterTicketsTable({
      namespace: TablesNamespaces.TicketsTable,
      filter: this.customFilter,
    });
  }

  updateFilterProblem() {
    this.problemFilter = !this.problemFilter;
    this.filterTicketsTable({
      namespace: TablesNamespaces.TicketsTable,
      filter: this.customFilter,
    });
  }

  createFilter(
      namespace: string,
      operator: FilterOperatorEnum,
      value: Array<string | number>
  ): TableFilterItem {
    return {
      column: namespace,
      operator: operator,
      values: value.map((item) => item.toString()),
    };
  }

  prepareAdvancedFilter() {
    let customFilter: TableFilter = [];

    if (this.numberFilter) {
      let filter = {
        filter: this.createFilter(
            TicketFilterEnum.Number,
            FilterOperatorEnum.Like,
            [this.numberFilter]
        ),
      };
      customFilter.push(filter);
    }

    if (this.statusFilter) {
      let filter = {
        filter: this.createFilter(
            TicketFilterEnum.Status,
            FilterOperatorEnum.Equal,
            [this.statusFilter]
        ),
      };
      customFilter.push(filter);
    }

    this.filterTicketsTable({
      namespace: TablesNamespaces.TicketsTable,
      filter: customFilter.concat(this.customFilter),
    });
  }

  tableOptions: TableOptions<Ticket, TicketFilterEnum> = {
    defaultSortBy: ["number"],
    refreshInterval: 60,
    filterableColumns: [
      {
        enumValues: [],
        value: TicketFilterEnum.Number,
        text: "Č. zakázky",
        type: TableColumnDataType.Number,
      },
      {
        enumValues: [],
        value: TicketFilterEnum.Created,
        text: "Zadáno",
        type: TableColumnDataType.DateTime,
      },
    ],
    headers: [
      {
        text: "Č. zakázky",
        sortable: true,
        align: "start",
        value: "number",
      },
      {
        text: "Odběratel",
        sortable: true,
        align: "start",
        value: "doctor",
      },
      {
        text: "Laborant",
        sortable: true,
        align: "start",
        value: "laborer",
      },
      {
        text: "Pacient",
        sortable: true,
        align: "start",
        value: "patient",
      },
      {
        text: "Zadáno",
        sortable: true,
        align: "start",
        value: "created",
      },
      {
        text: "Předání",
        sortable: true,
        align: "start",
        value: "process",
      },
      {
        text: "Cena",
        sortable: true,
        align: "start",
        value: "price",
      },
      {
        text: "Stanoviště",
        sortable: false,
        align: "start",
        value: "operations",
      },
      {
        text: "",
        sortable: false,
        align: "start",
        value: "important",
      },
      {
        text: "",
        sortable: false,
        value: "actions",
      },
    ],
    namespace: TablesNamespaces.TicketsTable,
  };

  async pin(ticket: Ticket): Promise<void> {
    const { data } = await apiClient.callGraphqlPrivate<
      PinTicketMutation,
      PinTicketMutationVariables
    >({
      ...api.ticket.pin,
      variables: {
        id: ticket.id,
      },
    });

    if (data?.pinTicket) {
      this.refreshPinnedTicketsTable({
        namespace: TablesNamespaces.PinnedTicketsTable,
      });
      this.refreshTicketsTable({
        namespace: TablesNamespaces.TicketsTable,
      });
      this.fetchStatusCards();
    }
  }

  currentOperationLaborer(operations: Operation[]): string {
    const reverseOperations = operations.slice().reverse();
    let operation = reverseOperations.find(
      (o) => o.status === OperationStatusEnum.InProgress
    );
    if (!operation) {
      operation = reverseOperations.find(
        (o) => o.status === OperationStatusEnum.Done
      );
    }

    if (operation) {
      const firstname = operation.laborer.firstname;
      const surname = operation.laborer.surname;
      return `${firstname} ${surname}`;
    }
    return "";
  }
}
