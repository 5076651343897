


































import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { Rules } from "../../../utils/veeValidate";

@Component({
  components: {
    ValidationProvider,
  },
})
export default class TextField extends Vue {
  @Prop({ required: true, type: String })
  private name!: string;

  @Prop({ required: false, type: String, default: "" })
  private label!: string;

  @Prop({ required: false, type: [String, Number], default: "" })
  private value!: string | number;

  @Prop({ required: false, type: Boolean, default: false })
  private customData!: boolean;

  @Prop({ required: false, type: [Object, String] })
  private rules?: Rules;

  @Prop({ required: false, type: String, default: "text" })
  private type!: "number" | "password" | "text";

  @Prop({ required: false, type: Boolean, default: false })
  private autofocus!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  private soloInverted!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  private flat!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  private disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  private hideDetails!: boolean;

  @Prop({ required: true, type: Array })
  private items!: Array<Record<string, string>>;

  @Prop({ required: false, type: String, default: "text" })
  private itemText!: string;

  @Prop({ required: false, type: String, default: "value" })
  private itemValue!: string;

  @Prop({ required: false, type: String })
  private width?: string;

  @Prop({ required: false, type: Boolean, default: false })
  private clearable!: boolean;

  @Prop({ required: false, type: String, default: () => null })
  private placeholder!: string | null;
}
