






































import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { Rules } from "../../../utils/veeValidate";
import { toLocalDate } from "../../../filters/datetime";

@Component({
  components: {
    ValidationProvider,
  },
  filters: {
    toLocalDate,
  },
})
export default class DateField extends Vue {
  private isOpen: boolean;
  private innerValue: any;

  @Prop({ required: false, type: [String], default: "" })
  private value!: string;

  @Prop({ required: false, type: String })
  private label?: string;

  @Prop({ required: true, type: String })
  private name!: string;

  @Prop({ required: false, type: String, default: "" })
  private hint!: string;

  @Prop({ required: false, type: [Object, String] })
  private rules?: Rules;

  @Prop({ required: false, type: Boolean, default: false })
  private disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  private allDay!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly!: boolean;

  constructor() {
    super();
    this.isOpen = false;
    this.innerValue = null;
  }

  formatForFilter(dateTime: string) {
    const day = new Date(dateTime);
    if (this.allDay) {
      day.setUTCHours(23, 59, 59);
    }

    return day.toISOString();
  }
}
