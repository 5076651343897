




























































import SvgIcon from "../../app/SvgIcon.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Routes } from "../../../enums/Routes";
import { WebsocketNotification } from "../../../store/modules/notifications";

@Component({
  components: {
    SvgIcon,
  },
})
export default class ToastNotification extends Vue {
  Routes = Routes;

  @Prop({ type: Boolean, default: false })
  value!: boolean;

  @Prop({ type: Object, required: false })
  readonly notification!: WebsocketNotification;

  @Prop({ type: Number, default: 2000 })
  readonly timeout!: number;

  isOpen = false;

  close() {
    this.$emit("close");
  }
}
