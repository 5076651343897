import { render, staticRenderFns } from "./NotificationSummary.vue?vue&type=template&id=41c72dd1&scoped=true&"
import script from "./NotificationSummary.vue?vue&type=script&lang=ts&"
export * from "./NotificationSummary.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationSummary.vue?vue&type=style&index=0&id=41c72dd1&lang=scss&scoped=true&"
import style1 from "./NotificationSummary.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41c72dd1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VSnackbar})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
