













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ChipButton extends Vue {
  @Prop({ required: false, type: Boolean, default: false })
  private active!: boolean;
}
