





















































































import { Component, Vue } from "vue-property-decorator";
import SideBarItem from "./SideBarItem.vue";
import { Routes } from "../../../../enums/Routes";

@Component({
  components: {
    SideBarItem,
  },
})
export default class SideBar extends Vue {
  routes = Routes;
}
