






































import { Component, Prop, Vue } from "vue-property-decorator";
import { Notification, ReadAllNotificationsMutation } from '../../../types/gqlGeneratedPrivate';
import { Action, Getter } from "vuex-class";
import {
  NotificationsActions,
  NotificationsGetters,
  notificationsNamespace,
} from "../../../store/modules/notifications";
import { EventBus, FETCH_PAGE_SIZE } from "../../../enums";
import { eventBus } from "../../../utils/eventBus";
import NotificationSummaryContent from "./NotificationSummaryContent.vue";
import Button from '@/components/commons/inputsAndControls/Button.vue';
import { apiClient } from '../../../utils';
import { api } from '../../../api';

@Component({
  components: { Button, NotificationSummaryContent },
})
export default class NotificationSummary extends Vue {
  @Action(`${notificationsNamespace}/${NotificationsActions.FetchMore}`)
  private loadNotifications!: (payload: {
    unread: boolean;
    page: number;
  }) => Promise<boolean>;
  @Action(`${notificationsNamespace}/${NotificationsActions.Clear}`)
  private clearNotifications!: () => Promise<boolean>;

  @Prop({ type: Boolean, default: true })
  readonly snackbar!: boolean;

  @Getter(
    `${notificationsNamespace}/${NotificationsGetters.GetUnreadNotifications}`
  )
  unreadNotifications!: Notification[];

  @Getter(
    `${notificationsNamespace}/${NotificationsGetters.GetReadNotifications}`
  )
  readNotifications!: Notification[];

  loadingUnreaded = false;
  unreadPage = 2;
  loadingReaded = false;
  readPage = 2;
  allReadLoaded = false;
  allUnreadLoaded = false;

  mounted() {
    if (this.readNotifications.length < FETCH_PAGE_SIZE) {
      this.allReadLoaded = true;
    }
    if (this.unreadNotifications.length < FETCH_PAGE_SIZE) {
      this.allUnreadLoaded = true;
    }
  }

  async markAllAsRead() {
    await apiClient.callGraphqlPrivate<ReadAllNotificationsMutation>({
      ...api.notifications.readAllNotifications,
    });
    await this.clearNotifications();
    await this.loadNotifications({ page: 1, unread: false });
    await this.loadNotifications({ page: 1, unread: true });
  }

  async onLoadMore(loadUnread = true): Promise<void> {
    if (loadUnread) {
      this.loadingUnreaded = true;
      const canLoadMore = await this.loadNotifications({
        unread: loadUnread,
        page: this.unreadPage,
      });
      this.allUnreadLoaded = !canLoadMore;
      this.unreadPage++;
      this.loadingUnreaded = false;
    } else {
      this.loadingReaded = true;
      const canLoadMore = await this.loadNotifications({
        unread: loadUnread,
        page: this.readPage,
      });
      this.allReadLoaded = !canLoadMore;
      this.readPage++;
      this.loadingReaded = false;
    }
  }

  onClickOutside() {
    eventBus.$emit(EventBus.NotificationToggleModal, false);
  }
}
